<template>
    <!-- Error page-->
    <div class="misc-wrapper">
        <b-link class="brand-logo">

        </b-link>

        <div class="misc-inner p-2 p-sm-3">
            <div class="w-100 text-center">
                <h2 class="mb-1">
                    Page not found
                </h2>
                <p class="mb-2">
                    {{i18nT(`Oops! The requested URL was not found on this server`)}}
                </p>

                <b-button
                    variant="primary"
                    class="mb-2 btn-sm-block"
                    @click="dashboardRoute"
                >
                    {{i18nT(`Back to home`)}}
                </b-button>

                <!-- image -->
                <b-img
                    fluid
                    :src="imgUrl"
                    alt="Error page"
                />
            </div>
        </div>
    </div>
    <!-- / Error page-->
</template>

<script>
/* eslint-disable global-require */
import { BLink, BButton, BImg } from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import store from '@/store/index'
import useAuth from "@/auth/useAuth";

export default {
    components: {
        VuexyLogo,
        BLink,
        BButton,
        BImg,
    },
    methods:{
        dashboardRoute() {
            //const user = JSON.parse(localStorage.getItem('userData'))
            //return getHomeRouteForLoggedInUser(user ? user.role : null)
            if(useAuth.getUserType()){
                let curSoftware = useAuth.getCurrentSoftware();
                if(curSoftware) {
                    this.$router.push({path: '/' + curSoftware + '/dashboard'});
                }else {
                    this.$router.push({path: '/'});
                }
            }else {
                this.logout();
            }
        },
    },
    data() {
        return {
            downImg: require('@/assets/images/pages/error.svg'),
        }
    },
    computed: {
        imgUrl() {
            if (store.state.appConfig.layout.skin === 'dark') {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.downImg = require('@/assets/images/pages/error-dark.svg')
                return this.downImg
            }
            return this.downImg
        },
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
